import { Course } from '../../api/endpoints/course';
import { throwErrorWithStack } from '../../utils/throw-error-with-stack';
import { db } from '../db';
import { CourseInDB, CourseInDBId } from './storage-course.type';

const checkCourseExists = async (id: CourseInDBId) => {
  const course = await db.courses.get(id);
  if (!course) {
    throw new Error(`Course does not exist with id: ${id}`);
  }
  return course;
};

export const courseStorageService = {
  create: async (course: Course) => {
    const courseToCreate: CourseInDB = {
      id: course.id,
      selectedLessonId: null,
    };

    const offlineCourse = await db.courses.get(course.id);

    if (!offlineCourse) {
      try {
        await db.courses.add(courseToCreate);
      } catch (error) {
        throwErrorWithStack(error);
      }
    }
  },

  readOne: async (id: CourseInDBId) => db.courses.get(id),

  readMany: async () => db.courses.toArray(),

  updateSelectedLessonId: async (
    id: CourseInDBId,
    selectedLessonId: NonNullable<CourseInDB['selectedLessonId']>,
  ) => {
    await checkCourseExists(id);
    try {
      await db.courses.update(id, { selectedLessonId });
    } catch (error) {
      throwErrorWithStack(error);
    }
  },
};
