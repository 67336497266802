import {
  RegisterUserQueryArgs,
  ResendEmailVerificationQueryArgs,
  LoginUserQueryArgs,
  ResetUserPasswordQueryArgs,
  PayloadWithEmail,
} from './api-user.type';

const transformPayloadWithEmail = <T>(
  payload: PayloadWithEmail<T>,
): PayloadWithEmail<T> => ({
  ...payload,
  email: payload.email.toLowerCase(),
});

export const transformRegisterUserPayload = (
  payload: RegisterUserQueryArgs['payload'],
) => transformPayloadWithEmail(payload);

export const transformResendEmailVerificationPayload = (
  payload: ResendEmailVerificationQueryArgs['payload'],
) => transformPayloadWithEmail(payload);

export const transformLoginUserPayload = (
  payload: LoginUserQueryArgs['payload'],
) => transformPayloadWithEmail(payload);

export const transformResetUserPasswordPayload = (
  payload: ResetUserPasswordQueryArgs['payload'],
) => transformPayloadWithEmail(payload);
