import React, { useEffect } from 'react';
import { Outlet, Navigate, Routes, Route } from 'react-router-dom';
import { InitialLoading } from './components/initial-loading';
import { useUser } from './providers/user';
import { isInsideIframe } from './utils/is-inside-iframe';
import { isRTKQueryError } from './utils/is-axios-error';

const CourseListings = React.lazy(
  () => import('./pages/course-listings/course-listings.component'),
);
const Login = React.lazy(() => import('./pages/login'));
const SignInPurchase = React.lazy(
  () => import('./pages/sign-in-purchase/sign-in-purchase.component'),
);
const Register = React.lazy(() => import('./pages/register'));
const VerifyEmail = React.lazy(
  () => import('./pages/verify-email/verify-email.component'),
);
const Logout = React.lazy(() => import('./pages/logout'));
const ErrorPage = React.lazy(() => import('./pages/error/error.component'));
const IframeErrorPage = React.lazy(
  () => import('./pages/iframe-error/iframe-error.component'),
);
const EditCourse = React.lazy(
  () => import('./pages/edit-course/edit-course.component'),
);
const Lesson = React.lazy(() => import('./pages/lesson/lesson.component'));
const AppVersion = React.lazy(() => import('./pages/app-version'));
const LessonsInCourse = React.lazy(
  () => import('./pages/lessons-in-course/lessons-in-course.component'),
);
const PasswordReset = React.lazy(
  () => import('./pages/password-reset/password-reset.component'),
);
const PasswordUpdate = React.lazy(
  () => import('./pages/password-update/password-update.component'),
);
const UserProfile = React.lazy(
  () => import('./pages/user-profile/user-profile.component'),
);
const PurchaseSuccess = React.lazy(
  () => import('./pages/purchase-success/purchase-success.component'),
);
const ShareTemplate = React.lazy(() => import('./pages/share/share.component'));
const PublicProfile = React.lazy(
  () => import('./pages/public-profile/public-profile.component'),
);
const PublicProfileUser = React.lazy(
  () => import('./pages/public-profile-user/public-profile-user.component'),
);

const TeacherRoutes = () => {
  const {
    account: { data: user },
  } = useUser();

  const isTeacher = user?.role === 'teacher';
  return isTeacher ? <Outlet /> : <Navigate to='/' />;
};

const PrivateRoutes = () => {
  const {
    auth: { isLoggedIn },
    account: {
      loading: { isReadAccountLoading: isReadUserLoading },
    },
  } = useUser();

  if (isReadUserLoading) {
    return null;
  }

  const beforeLoginPath = window.location.pathname + window.location.search;

  // TODO: investigate why login sometimes ends up in the beforeLoginPath upon logout
  if (!isLoggedIn && !['/login', '/logout'].includes(beforeLoginPath)) {
    sessionStorage.setItem('beforeLoginPath', beforeLoginPath);
  }
  return isLoggedIn ? <Outlet /> : <Navigate to='/login' />;
};

const RestrictedRoutes = () => {
  const {
    auth: { isLoggedIn },
    account: {
      loading: { isReadAccountLoading: isReadUserLoading },
    },
  } = useUser();

  if (isReadUserLoading) {
    return null;
  }

  const beforeLoginPath = sessionStorage.getItem('beforeLoginPath');

  if (isLoggedIn && beforeLoginPath) {
    sessionStorage.removeItem('beforeLoginPath');
    return <Navigate to={beforeLoginPath} />;
  }

  return !isLoggedIn ? <Outlet /> : <Navigate to='/courses' />;
};

export const AppRoutes = () => {
  /**
   * Although, the response from the query below is never read in this component,
   * we still call it here to ensure the response is cached for any subsequent calls
   * to the same query by the children of this component. This allows us to show the
   * loading state only in one place (this component) and not in every place that we
   * call this query.
   */
  const {
    account: {
      read: readUser,
      loading: { isReadAccountLoading: isReadUserLoading },
      error: { read: readUserError },
    },
  } = useUser();

  useEffect(() => {
    void readUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    isReadUserLoading ||
    (isRTKQueryError(readUserError) && readUserError?.status === 429)
  ) {
    return <InitialLoading />;
  }

  if (isInsideIframe()) {
    return (
      <Routes>
        <Route path='/*' element={<IframeErrorPage />} />
        <Route path='share/:shareId' element={<ShareTemplate />} />
        <Route path='/lesson/:courseId'>
          <Route path=':lessonId' element={<Lesson />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path='/courses' element={<CourseListings />} />
      <Route path='/' element={<Navigate to='/login' />} />
      <Route path='/home' element={<Navigate to='/login' />} />
      <Route path='/*' element={<ErrorPage />} />
      <Route path='/courses/:courseId' element={<LessonsInCourse />} />
      <Route path='/lesson/:courseId'>
        <Route path='' element={<Lesson />} />
        <Route path=':lessonId' element={<Lesson />} />
      </Route>
      <Route
        path='/courses/:courseId/purchaseSuccess'
        element={<PurchaseSuccess />}
      />
      <Route path='/profile/:username' element={<PublicProfile />} />
      <Route element={<PrivateRoutes />}>
        <Route path='/profile/preview' element={<PublicProfileUser />} />
        <Route path='/profile' element={<UserProfile />} />
        <Route path='/logout' element={<Logout />} />
        <Route element={<TeacherRoutes />}>
          <Route path='/courses/edit'>
            <Route path=':courseId' element={<EditCourse />} />
            <Route path='' element={<EditCourse />} />
          </Route>
        </Route>
      </Route>
      <Route element={<RestrictedRoutes />}>
        <Route path='/login' element={<Login />} />
        <Route path='/sign-in-purchase' element={<SignInPurchase />} />
        <Route path='/register' element={<Register />} />
        <Route path='/verify' element={<VerifyEmail />} />
        <Route path='/passwordReset' element={<PasswordReset />} />
        <Route path='/passwordUpdate/:token' element={<PasswordUpdate />} />
      </Route>
      <Route path='/version' element={<AppVersion />} />
      <Route path='share/:shareId' element={<ShareTemplate />} />
    </Routes>
  );
};
